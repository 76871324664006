// insert trained custom models pairs with their category ID here.

// Data Format:
// Model Type -- src language -- target language -- category ID (for API call)  *** THIS NEEDS TO BE UPDATED ***

// For each model type make sure to include an object with all empty values first, check below.
// This is to ensure an empty selection from the dropdown menus

const trainedModelPairs = [
  {
    "model type": "",
    "categoryID": "",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      }
    ]
  },

  {
    "model type": "Lilly Global (General)",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-GENERAL", // TODO: Consider putting these in .env file for additional obfuscation
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "German", "tcode": "de"},
          {"tname": "French", "tcode": "fr"},
          {"tname": "Italian", "tcode": "it"},
          {"tname": "Japanese", "tcode": "ja"},
          {"tname": "Portuguese (Brazil)", "tcode": "pt"},
          {"tname": "Chinese (Simplified)", "tcode": "zh-Hans"},
          {"tname": "Spanish (Latin)", "tcode": "es"}
        ]
      },
      {
        "sname": "German",
        "scode":"de",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "French",
        "scode":"fr",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Italian",
        "scode":"it",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Japanese",
        "scode":"ja",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Portuguese (Brazil)",
        "scode":"pt",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Chinese (Simplified)",
        "scode":"zh-Hans",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Spanish (Latin)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Ethics & Compliance",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-EC-CUSTOM",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "German", "tcode": "de"},
          {"tname": "French", "tcode": "fr"},
          {"tname": "Spanish (Latin)", "tcode": "es"},
          {"tname": "Italian", "tcode": "it"},
          {"tname": "Japanese", "tcode": "ja"},
          {"tname": "Portuguese (Brazil)", "tcode": "pt"},
          {"tname": "Chinese (Simplified)", "tcode": "zh-Hans"},
          {"tname": "Korean", "tcode": "ko"},
          {"tname": "Spanish (Spain)", "tcode": "es-xn"},
          {"tname": "Turkish", "tcode": "tr"},
          {"tname": "Chinese (Traditional)", "tcode": "zh-Hant"},
          {"tname": "Hungarian", "tcode": "hu"},
          {"tname": "Polish", "tcode": "pl"},
          {"tname": "Slovak", "tcode": "sk"},
          {"tname": "Greek", "tcode": "el"}
        ]
      },
      {
        "sname": "German",
        "scode":"de",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "French",
        "scode":"fr",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Italian",
        "scode":"it",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Japanese",
        "scode":"ja",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Portuguese (Brazil)",
        "scode":"pt",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Spanish (Latin)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Chinese (Simplified)",
        "scode":"zh-Hans",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Korean",
        "scode":"ko",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Spanish (Spain)",
        "scode":"es-xn",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Turkish",
        "scode":"tr",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Chinese (Traditional)",
        "scode":"zh-Hant",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Hungarian",
        "scode":"hu",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Polish",
        "scode":"pl",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Slovak",
        "scode":"sk",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Greek",
        "scode":"el",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Enterprise Brand Websites",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-EnterpriseBrandSites-INTERNT",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Italian", "tcode": "it"},
          {"tname": "Spanish (Latin)", "tcode": "es"}
        ]
      },
      {
        "sname": "Italian",
        "scode":"it",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      },
      {
        "sname": "Spanish (Latin)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Medicines Development Unit (MDU)",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-MDU-HEALTHC",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Japanese", "tcode": "ja"}
        ]
      },
      {
        "sname": "Japanese",
        "scode":"ja",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }
    ]
  },

  {
    "model type": "Marketing",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-MKTNG-TELECOM",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Spanish (Latin)", "tcode": "es"}
        ]
      }/*,
      {
        "sname": "Spanish (Latin)",
        "scode":"es",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "English", "tcode": "en"}
        ]
      }*/
    ]
  }/*,

  {
    "model type": "ChatNow",
    "categoryID": "576b855e-9624-4e29-8b53-c6e7fe298d06-CHATNOW-SPEECH",
    "models": 
    [
      { // Blank Entry
        "sname": "",
        "scode":"",
        "target langs": 
        [
          {"tname": "", "tcode": ""}
        ]
      },
      { // English -> ...
        "sname": "English",
        "scode":"en",
        "target langs": 
        [
          {"tname": "", "tcode": ""},
          {"tname": "Japanese", "tcode": "ja"},
          {"tname": "German", "tcode": "de"},
          {"tname": "French", "tcode": "fr"},
          {"tname": "Italian", "tcode": "it"}
        ]
      }
    ]
  }*/
]

export default trainedModelPairs;