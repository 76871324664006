import React, {
  Fragment,
  useState,
  useEffect
} from "react";
import {
  isMobile
} from "react-device-detect";
import Lottie from 'react-lottie';
import uuid from 'uuid';
import * as clipboard from "clipboard-polyfill"
import {
  Button,
  Box,
  TextField,
  Grid,
  Typography,
  Tabs,
  Tab,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Card,
  CardContent,
  Switch,
  Tooltip,
  Zoom
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import "../styles/App.css";
import S3Encoding from "../utils/S3Encoding";
import aniData from "../data/ani-cloud.json";
import getSpeechVoices from "../data/text-speech-data";
import API from '../API';
import { useHistory } from "react-router-dom";
// import cciPairs from "../data/pairs-cci"
// MSAL Imports
import { useAccount, useMsal } from "@azure/msal-react";

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import StopIcon from '@material-ui/icons/Stop';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// *************************************************************************
// Overall Settings

// Speech limit settings
const textMax = 20000;

// Timing settings
const intervalInMs = 1000; // prod should be 1000
const intervalInMsDrop0 = 2000; // prod should be 2000
const intervalInMsDrop1 = 5000; // prod should be 5000
//const intervalInMsPlus0 = 10000; // prod should be 10000 // keep in sync
//const intervalInMSPlus1 = 50000; // prod should be 50000 // keep in sync
//const intervalInMSPlus2 = 60000; // prod should be 60000 // keep in sync
const maxInMs = 900000; // prod should be 300000
const maxInMsDeltaDrop0 = 30000; // prod should be 10000
const maxInMsDeltaDrop1 = 180000; // prod should be 60000
const maxInMsDeltaDrop2 = 360000; // prod should be 120000
const maxInMsDeltaDrop3 = 540000; // prod should be 180000
const maxInMsDeltaDrop4 = 720000; // prod should be 240000

// UI settings
const textTab = 0;
const docTab = 1;
const resultTab = 2;
const ttsTab = 0;
const sttTab = 1;
const toLabelDefault = "Speech Results";
const toLabelError = "Speech Challenge";
const toLabelProgress = "In Progress";
const toLabelGeneration = "Generation";

// Animation settings
const aniOptions = {
  loop: true,
  autoplay: true,
  animationData: aniData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

// Transition settings
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// *************************************************************************
// Overall Error Messages
// If these are added/deleted/changed than the guide-support needs to be updated

const errorMsgDefault = "Uh oh! We faced an unexpected error. [default]\n\nFirst, please checkout out our Tips & FAQs - If you are still unsure, please contact us\n\nIf you are able to share the document you are trying to convert, it may help us to resolve the error. If you cannot, please let us know what criteria you are using and a description of what you are trying to convert. Thank you!";
// Stage 1 should support PDF, so remove msg when transition is done
const errorMsgTime = "Uh oh! We faced an unexpected error. [time]\n\nPlease contact us \n\nIt appears we ran out of time for your request. This usually means that there was more content than we could handle at this time. The amount of content is related to the total number of characters and complexity. It is not related to the file size. We recommend trying to reduce how much content is in the document or text and then trying again. For example, a .docx file can be split in half or a .xlsx file could be converted with fewer sheets. Please let us know about this challenge so we can learn from it and improve our performance. Thank you!";
const errorMsgText = "Uh oh! We faced an unexpected error. [text]\n\nPlease contact us \n\nIt appears you are trying to convert more text than we can currently support. Currently we can only support a maximum length of 5000 characters. Please try removing some content from your text and try again. Additionally, at the top of the text area there is label that looks simliar to 'Text to Convert (123/5000)'. The '123' in this example tells you how many characters you have used so far. If you are still having a challenge with the text, please contact us with the text you are trying to convert. Thank you!"
const errorMsgFileZero = "Uh oh! We faced an unexpected error. [zero]\n\nPlease contact \n\nIt appears your request was with an empty file with a size of 0 KB. Please use a file that has content inside of it. If you are still having a challenge with the file, please contact us with the file you are trying to convert. Thank you!";
const errorMsgName = "Uh oh! We faced an unexpected error. [name]\n\nPlease contact us \n\nIt appears you are trying to upload a file that has a file name with characters we do not support yet. Try renaming the file and uploading again. If you are still having a challenge with the file, please contact us with the name of the file you are trying to convert. Thank you!";
const errorMsg422 = "Uh oh! We faced an unexpected error. [422]\n\nPlease contact us \n\nIf you are able to share the document or text you are trying to convert, it may help us to resolve the error. If you cannot, please let us know what criteria you are using and a description of what you are trying to convert. Thank you!";
const errorMsgCopy = "Uh oh! We faced an unexpected error. [copy]\n\nPlease contact us \n\nIt appears copying to the clipboard is not compatible with your browser version. Please contact us with your browser version and the type of mobile device if applicable. Thank you!";


export default function TextSpeech(){
  
  const history = useHistory();

  // State Variables
  const [userID, setUserID] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userCountry, setUserCountry] = useState("");

  const [UIDcaptured, setUIDcaptured] = useState(false);
  const [audioRecorded, setAudioRecorded] = useState(false);  // THIS WILL BE USED IN THE FUNCTION FOR HANDLING IN-BROWSER AUDIO RECORDING

  const [language, setLanguage] = useState("");
  const [voice, setVoice] = useState("");
  const [gender, setGender] = useState("(Female)"); // Default to (Female) when using Toggle switch

  const [genderDisabled, setGenderDisabled] = useState(true);
  const [voiceDisabled, setVoiceDisabled]= useState(true);

  const [fromText, setFromText] = useState("");
  const [fromLabel, setFromLabel] = useState(`Text to Convert (0/${textMax})`);
  const [fromFileName, setFromFileName] = useState("");
  const [fromFileExt, setFromFileExt] = useState("");
  // const [toFileExt, setToFileExt] = useState(""); // CURRENTLY UN-USED - Included to allow for supporting other file extensions
  const [fromFile, setFromFile] = useState(null);
  const [fromTTSFileExtAllowed, setfromTTSFileExtAllowed] = useState(["txt"])
  const [fromSTTFileExtAllowed, setfromSTTFileExtAllowed] = useState(["wav"])

  const [uploadKey, setUploadKey] = useState("");
  const [downloadKey, setDownloadKey] = useState("");
  const [downloadFailKey, setDownloadFailKey] = useState("");

  const [toText, setToText] = useState("");
  const [toLabel, setToLabel] = useState(toLabelDefault);
  const [toFileUrl, setToFileUrl] = useState("");
  const [toFailFileUrl, setToFailFileUrl] = useState("");

  const [outFn, setOutFn] = useState("");
  const [filetype, setFiletype] = useState("audio/wav");  // Setting this at the start since we default to the TTS page when we load in
  
  const [disableTextProcessing, setDisableTextProcessing] = useState(true);
  const [disableTextCopy, setDisableTextCopy] = useState(true);
  const [disableTextFullScreen, setDisableTextFullScreen] = useState(true);
  const [disableDocProcessing, setDisableDocProcessing] = useState(true);
  const [disableDownload, setDisableDownload] = useState(true);
  const [disableDocUpload, setDisableDocUpload] = useState(false);

  const [disableTTS, setDisableTTS] = useState(false);
  const [disableSTT, setDisableSTT] = useState(false);

  const [disableTextTab, setDisableTextTab] = useState(false);

  const [showDownload, setShowDownload] = useState(true);
  const [showFullText, setShowFullText] = useState(false);

  const [contentTab, setContentTab] = useState(textTab);
  const [speechTab, setSpeechTab] = useState(0);
  const [activeJob, setactiveJob] = useState(false);
  const [activeJobID, setactiveJobID] = useState("");
  const [activeJobTime, setactiveJobTime] = useState(null);

  const [speechTitle, setSpeechTitle] = useState("Generation");
  const [textPage, setTextPage] = useState("Text to Speech");
  const [docPage, setDocPage] = useState("Document to Speech");

  const [permission, setPermission] = useState(false);
  const mediaRecorder = React.useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [disableRecording, setDisableRecording] = useState(true);
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);

  // Array contains all Language Pairs
  const [azureLanguages, setAzureLanguages] = useState([])

  // MSAL STATE HOOKS
  // fetch our Public Client Application instance
  const {instance} = useMsal();
  // Fetch current Active Account
  const activeAccount = useAccount()

  // To avoid user scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll"
    };
  }, []);
  
  // Called whenever activeAccount changes
  useEffect(() => {

    getSpeechVoices().then((res) => {
      setAzureLanguages(res)
    })

    // ID Handling for CA accounts using Cognitive AI
    if (String(activeAccount.username).includes("-CA")) {
      setUserID(String(String(activeAccount.username).split('-CA')[0]));
    }
    else {
      setUserID(activeAccount.idTokenClaims.employee_id);
    }
    
    setUserEmail(activeAccount.username);
    setUserFullName(activeAccount.name);

    // console.log(activeAccount.idTokenClaims.employee_id);
    // console.log(activeAccount.username);
    // console.log(activeAccount.name);
    // console.log("ABOVE IS THE ACTIVE ACCOUNT INFO FOR LOGGING");

    // Get user country info
    try {
      fetch("https://ipapi.co/country_name")
        .then(res => res.text())
        .then(
          (result) => {
            setUserCountry(result);
          },
          (error) => {
            console.error(error);
            setUserCountry("LillyDefault");
          }
        )
    } catch (e) {
      // ignore this error if it occurs
      console.log("Country Fetch error: " + e.message);
    }
  }, [activeAccount])


  // Called whenever userID actually gets updated and can be sent through for API calls
  useEffect(() => {
    setUIDcaptured(true);
    
  }, [userID])

  // Called when one of the dependencies change
  useEffect(() => {
    handleValidateTabs();
  }, [language, voice, gender, contentTab, fromFileName, fromFileExt, fromFile, UIDcaptured]);
  
  useEffect(() => {
    handleValidateTabsDelayed();
  }, [fromText, fromLabel]);


  // *************************************************************************
  // Handle events

  // Populate drop downs
  const handleGenerateLanguageOptions = (opts) => {
    return opts.map(opt => {
      return ( <option value={opt.locale} key={opt.key}> {opt.language} </option>);
    });
  }

  const handleGenerateVoiceOptions = (opts) => {
    return opts.map(opt => {
      if (opt["locale"] == language)
      {
        return opt["voices"].map(singleOption => {
          if (singleOption.gender == gender || singleOption.gender=="") {
            return ( <option value={singleOption.name} key={singleOption.name}> {singleOption.name} </option>);
          }
        })
      }
    });
  }

  const toggleGenders = () => {
    
    if (gender === "(Male)") {
      setGender("(Female)")
    }
    else {
      setGender("(Male)")
    }
  }

  // Check conditions to see if convert button is pressable
  const handleValidateTabs = () => {
    
    // Check to see which speech service we are using and validate accordingly
    if (speechTab === ttsTab) {
      var validBoolText = language && voice && gender && fromText && fromText.trim() && (activeJob === false) && (UIDcaptured === true); // No need to validate file extension since we create the temporary txt file ourselves
      var validBoolDoc = language && voice && gender && fromFileName && (activeJob === false) && (UIDcaptured === true) && (fromTTSFileExtAllowed.includes(fromFileExt));
    }
    else if (speechTab === sttTab) {
      var validBoolText = language && audioRecorded && (activeJob === false) && (UIDcaptured === true); // No need to validate file extension since we create the temporary wav file ourselves
      var validBoolDoc = language && fromFileName && (activeJob === false) && (UIDcaptured === true) && (fromSTTFileExtAllowed.includes(fromFileExt));
    }
    else {
      console.error("Somehow a value for speechTab that shouldn't be possible has been set: ", speechTab)
      console.error("Forcing buttons to be disabled until new tab choice is selected.")

      var validBoolText = false;
      var validBoolDoc = false;
    }
    
    const enableTextConvertButtonCondition = validBoolText;
    const enableDocConvertButtonCondition = validBoolDoc;
    
    
    if (contentTab === textTab) {
      // checking conditions to decide if button is to be pressable
      if (enableTextConvertButtonCondition) {
        setDisableTextProcessing(false);
      }  
      else {
        setDisableTextProcessing(true);
      }

    } 
    else if (contentTab === docTab) {
      
      if (activeJob === false) {
        setDisableDocUpload(false);
      } 
      else {
        setDisableDocUpload(true);
      }
      // checking conditions to decide if button is to be pressable
      if (enableDocConvertButtonCondition) {
        setDisableDocProcessing(false);
      } 
      else {
        setDisableDocProcessing(true);
      }
            
      if (activeJob === false) {
        setDisableDocUpload(false);
      } 
      else {
        setDisableDocUpload(true);
      }
      // checking conditions to decide if button is to be pressable
      if (enableDocConvertButtonCondition) {
        setDisableDocProcessing(false);
      } 
      else {
        setDisableDocProcessing(true);
      }
    }
  }

  const handleValidateTabsDelayed = () => {
    setTimeout(handleValidateTabs, 1000);
  }

  const handleResetCriteria = () => {
    setLanguage("");
    setVoice("");
    // setGender("(Female)");
  }

  const handleResetDocTab = () => {
    setFromFileName("");
    setFromFileExt("");
    setFromFile(null);
    setUploadKey("");
    setDownloadKey("");
    setDownloadFailKey("");
  }

  const handleResetResultTab = () => {
    setToText("");
    setToLabel(toLabelDefault);
    setDisableTextCopy(true);
    setDisableTextFullScreen(true);
    setDisableDownload(true);
    setDisableRecording(true);
    setDisableTextProcessing(true);
    setDisableDocProcessing(true);
    // setShowDownload(false);    // Defaults to showing the download button no matter what
  }

  // Handling a selection from the dropdowns
  const handleChangeLangChoice = event => {
    setLanguage(event.target.value);
    setVoice("")
    // setGender("")
    if (event.target.value != "") {
      setGenderDisabled(false)
      setVoiceDisabled(false)
    }
    else {
      // setGenderDisabled(true)
      setVoiceDisabled(true)
    }
  }

  const handleChangeVoiceChoice = event => {
    setVoice(event.target.value)
  }

  const handleChangeTabChoice = (event, newValue) => {
    setContentTab(newValue);
    handleScrollBottom();

    // If we switch to the record audio tab of the speech to text page and we don't already have permission to use their devices microphone we will request permission
    if ((newValue === textTab) && (speechTab === sttTab) && (permission === false)) {
      getMicrophonePermission();
    }
  }

  const handleChangeSpeechTabChoice = (event, newValue) => {
    if (newValue === ttsTab) {
      setSpeechTitle("Generation");
      setTextPage("Text to Speech");
      setDocPage("Document to Speech");
      handleResetDocTab();  // Reset uploaded file when the speech jobType tab changes
      // setDisableTextTab(false);   // USED TO MANUALLY DISABLE DIRECT SPEECH TO TEXT UNTIL THE IN BROWSER RECORDING IS IMPLEMENTED
      setFiletype("audio/wav");  // Force output file extension to .wav since we only support .wav at this time for speech generation downloads
    }
    else if (newValue === sttTab){
      setSpeechTitle("Transcription");
      setTextPage("Record Audio");
      setDocPage("Upload Audio Files");
      handleResetDocTab();  // Reset uploaded file when the speech jobType tab changes
      setContentTab(docTab);  // Default to the file upload tab so that it doesn't immediately prompt the user for recording permissions
      // setDisableTextTab(true);   // USED TO MANUALLY DISABLE DIRECT SPEECH TO TEXT UNTIL THE IN BROWSER RECORDING IS IMPLEMENTED
      setFiletype("text/plain");  // Force output file extension to .txt since we only support .txt at this time for speech transcription downloads
    }
    else {
      console.error("A value for speech tab that should not be possible has been chosen, we will refresh the page to fix this: ", newValue);
      window.location.reload();
    }

    setSpeechTab(newValue);
    setVoiceDisabled(true);
    handleResetCriteria();
    setLanguage("");
    handleScrollBottom();
  }
  
  const handleChangeFromText = event => {
    setFromText(event.target.value);
    setFromLabel(`Text to Speak (${event.target.value.length}/${textMax})`);
  }

  const handleChangeToText = event => {
    setToText(event.target.value);
  }

  const handleChangeUpload = event => {
    const curFile = event.target.files[0];
    if (curFile === undefined) {
      handleResetDocTab();
      return;
    }

    if (curFile.size === 0) {
      alert("Selected File is Empty");
      handleResetDocTab();
      return;
    }
    
    // Filter for STT files that are greater than 1GB
    if ((speechTab === sttTab) && (curFile.size > 1 * 1000 * 1000 * 1024)) {
      alert("Max Audio Upload Size is 1GB");
      handleResetDocTab();
      return;
    }

    // Filter for TTS files that are greater than 10KB
    if ((speechTab === ttsTab) && (curFile.size > 10  * 1024)) {
      alert("Max Text File Upload Size is 10KB, try breaking your file into chunks");
      handleResetDocTab();
      return;
    }

    let fileExt = curFile.name.split('.').pop().toLowerCase();

    if (S3Encoding.isASCII(curFile.name) === false) {
      // Remove Non-ASCII characters
      var clean_name = curFile.name.replace(/[\u{0080}-\u{FFFF}]/gu, "");

      // If the filename is only made up of ASCII characters we are going to have to set a new filename for them based on their global ID
      if (clean_name.split('.')[0].length === 0) {
        var uique_id = uuid.v4();
        clean_name = "file_" + uique_id + "." + fileExt;
      }
      
      setFromFileName(clean_name);
    }
    else {
      setFromFileName(curFile.name);
    }

    setFromFileExt(fileExt);
    setFromFile(curFile);
  }

  const handleClickConvert = event => {
    handleTimer(true);

    handleResetResultTab();
    
    setactiveJob(true);

    // Handle Text-to-Speech Jobs
    if (speechTab === ttsTab) {
      if (contentTab === textTab) {
        // This is done outside the validation function for performance while typing
        if (fromText.length > textMax) {
          handleError(null, errorMsgText);
        } 
        else {
          convertTextToSpeech();
        }
      } 
      else if (contentTab === docTab) {
        convertDocToSpeech();
      }
    }
    // Handle Text-to-Speech Jobs
    else if (speechTab === sttTab) {
      // textTab will refer to the tab where the user can record audio directly in the browser
      if (contentTab === textTab) {
        convertRecordingToText();
      }
      // docTab will refer to the tab where the user can upload an audio file from their computer
      else if (contentTab === docTab) {
        convertUploadToText();
      }
    }
  }

  const handleClickCopy = event => {
    try {
      clipboard.writeText(toText);
    } 
    catch (error) {
      handleError(error, errorMsgCopy);
    }
  }

  const handleClickDownload = event => {
    fetch(toFileUrl)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob.slice(0, blob.size, String(filetype)));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = String(outFn)
        document.body.appendChild(a);
        if(a.download !== "null")
        {
            a.click();
            // alert('Your Translated file ' + a.download + ' has downloaded!');
        }
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('Could not download file.'));

    // *** OG CODE ***
    // ***************
    // if (window.open) {
      // window.open(toFileUrl, '_blank').focus();
    // } else {
      //window.location = toFileUrl;
    // }
  }

  const handleClickCancel = event => {
    window.location.reload();
  }

  const handleCloseFullText = event => {
    setShowFullText(false);
  }

  const handleError = (error, displayMsg) => {
    console.log("** ERROR OCCURED **");
    if (error) {
      if (error.message) {
        console.log(error.message);
      } 
      else {
        console.log(error);
      }
    }

    let errorText = "";
    if (displayMsg) {
      errorText = displayMsg;
    } else {
      errorText = errorMsgDefault;
    }

    setToLabel(toLabelError);
    setToText(errorText);
    setDisableTextCopy(true);
    setDisableTextFullScreen(true);
    setDisableDownload(true);
    setDisableRecording(false);
    setContentTab(resultTab);
    setactiveJob(false);
    setactiveJobID("");

    handleResetDocTab();    
    handleTimer(false);
    handleScrollBottom();
  }

  const handleTranslateID = () => {
    var translationUUID = uuid.v4();
    setactiveJobID(translationUUID);

    return translationUUID;
  }

  const handleTimer = (restartTimer) => {
    if (process.env.REACT_APP_TIMER === "on") {
      if (restartTimer) {
        setactiveJobTime(Date.now());
      } 
      else {
        let dateDiffSec = ((Date.now() - this.state.activeJobTime) / 1000).toFixed(3);
        let dateDiffMin = (dateDiffSec / 60).toFixed(3);
        setactiveJobTime(`sec:${dateDiffSec}; min:${dateDiffMin}`);
      }
    }
  }
  
  const handleScrollBottom = () => {
    window.scrollTo(0,document.body.scrollHeight);
  }

  const startRecording = async () => {
    setRecordingStatus("recording");

    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: "audio/wav" });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
       if (typeof event.data === "undefined") return;
       if (event.data.size === 0) return;
       localAudioChunks.push(event.data);
    };
    
    setAudioChunks(localAudioChunks);
    setAudioRecorded(false);
  };

  const stopRecording = () => {
    setRecordingStatus("finished");

    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {

      //creates a blob file from the audiochunks data
       const audioBlob = new Blob(audioChunks, { type: "audio/wav" });

       setFromFile(audioBlob);

      //creates a playable URL from the blob file.
       const audioUrl = URL.createObjectURL(audioBlob);
      
       setAudio(audioUrl);
       setAudioChunks([]);
    };

    setFromFileExt("wav");
    setAudioRecorded(true);
  };

  const getMicrophonePermission = async () => {
      if ("MediaRecorder" in window) {
          try {
              const streamData = await navigator.mediaDevices.getUserMedia({
                  audio: true,
                  video: false,
              });
              setPermission(true);
              setDisableRecording(false);
              setStream(streamData);

          } catch (err) {
              alert(err.message);
          }
      }
      else {
        alert("Audio recording is unfortunately not supported in your browser.");
      }
  };

  // *************************************************************************
  // Handle UI Recorded Speech Audio Transcription (STT - Mic Recorded in Browser)

  const convertRecordingToText = async () => {
    try {
      transcribeUpdates(0);
      let tID = handleTranslateID();

      // Create a temporary txt file in the client-side browser local storage that we will use to submit the TTS job
      var fn = String(userID + "_" + String(tID) + "_recording")
      let keys = createRecordingTranscribeKeys(fn);
      
      // make a normal file API call, fromFile should be the audio blob from our recording
      let URLs = await API.submitTextToSpeech(fromFile, String(keys[0]), String(keys[1]), String(keys[2]));

      setToFileUrl(URLs[0]);
      setToFailFileUrl(URLs[1]);
      transcribeUpdates(3, "NULL", URLs);        

    } catch (error){
      handleError(error, errorMsg422);
    }
  }

  const createRecordingTranscribeKeys = (fn) => {
    // TODO: Differentiate filenames from source and target to increase back-end flexibility
    // Upload and download keys are the same for back-end compatibility purposes
    let uKey = `private/UI/speechToText/${userID}/${language}/${fn}.wav`
    let downloadFailKey = `${uKey}_FAIL.json`;
    let dKey = `private/UI/speechToText/${userID}/${language}/${fn}.txt`

    setOutFn(fn);

    return [uKey, downloadFailKey, dKey];
  }

  // *************************************************************************
  // Handle Uploaded Speech Audio File Transcription (STT - Doc)

  const convertUploadToText = async () => {   
    try {
      transcribeUpdates(0);
      let tID = handleTranslateID();
      let keys = createTranscribeKeys(tID);
      
      // make a normal file API call
      let URLs = await API.submitTextToSpeech(fromFile, String(keys[0]), String(keys[1]), String(keys[2]));

      setToFileUrl(URLs[0]);
      setToFailFileUrl(URLs[1]);
      transcribeUpdates(3, "NULL", URLs);        

    } catch (error){
      handleError(error, errorMsg422);
    }
  }

  const createTranscribeKeys = (tID) => {
    // TODO: Differentiate filenames from source and target to increase back-end flexibility
    // Upload and download keys are the same for back-end compatibility purposes

    let blankedFromFileName = S3Encoding.removeSpecials(fromFileName);
    let prefixBlankedFromFileName = blankedFromFileName.substr(0, blankedFromFileName.lastIndexOf("."));
    prefixBlankedFromFileName = prefixBlankedFromFileName.split(".").join("_");
    tID = prefixBlankedFromFileName;

    setOutFn(tID);

    // NOTE: "NONE" is used in this directory scheme to allow for a single lambda to handle both TTS and STT jobs
    // TODO: Update this approach to no longer use the folder directory structure to dictate metadata parameters
    let uKey = `private/UI/speechToText/${userID}/${language}/${tID}.${fromFileExt}`
    let downloadFailKey = `${uKey}_FAIL.json`;
    let dKey = `private/UI/speechToText/${userID}/${language}/${tID}.txt`

    return [uKey, downloadFailKey, dKey];
  }

  const transcribeUpdates = (step, data, meta) => {
    let msg0 = `Hello! Your text to speech transcription is now in progress!\n\n`;
    let msg1 = `${msg0}[Uploading audio ${data}%]\n\n`;
    let msg2 = `${msg0}Audio uploaded\n\n`;
    let msg3 = `${msg2}[Generating speech transcription...]\n`;
    let msg4 = `${msg3}... just letting you know, we are still here generating...`;
    let msg5 = `${msg3}... not to worry, this can take up to 15 minutes for longer audio...`;
    let msg6 = `${msg3}... we are good, doing lots of generating...`;
    let msg7 = `${msg3}... no errors, still generating...`;
    let msg8 = `${msg3}... there is about 3 minutes left of available time...`;
    let msg9 = `[ ** COMPLETE ** ]\n\nYour transcribed text is complete! Please download it by clicking the button below.\n\nThank you for using Lilly Translate :)`;

    switch (step) {
      case 0:
        setShowDownload(true);
        setDisableDownload(true);
        setToLabel(toLabelProgress);
        setToText(msg0);
        setContentTab(resultTab);

        handleScrollBottom();

        break;
      case 1:
        setToText(msg1);

        break;
      case 2:
        setToText(msg2);

        transcribeUpdates(3);

        break;
      case 3:
        setToText(msg3);

        let dropStatus = [false, false, false, false, false];
        // continuousPollForTranscrption(intervalInMs, maxInMs, toFileUrl, toFailFileUrl, dropStatus); // NEED TO FIGURE OUT WHY THIS DOESN'T WORK
        continuousPollForTranscrption(intervalInMs, maxInMs, meta[0], meta[1], dropStatus);

        break;
      case 4:
        setToText(msg4);

        break;
      case 5:
        setToText(msg5);

        break;
      case 6:
        setToText(msg6);

        break;
      case 7:
        setToText(msg7);

        break;
      case 8:
        setToText(msg8);

        break;
      case 9:  
        setToText(msg9);
    
        setToLabel(toLabelGeneration);
        setDisableDownload(false);
        setDisableRecording(false);
        setactiveJob(false);
        setactiveJobID("");
        setContentTab(resultTab);
        
        handleTimer(false);
        handleScrollBottom();

        break;
      default:
        break;
    }
  }

  // *************************************************************************
  // Handle Text to Speech

  const convertTextToSpeech = async () => {
    try {
      textUpdates(0);
      let tID = handleTranslateID();

      // Create a temporary txt file in the client-side browser local storage that we will use to submit the TTS job
      var fn = String(userID + "_" + String(tID) + "_script")
      sessionStorage.setItem(String(fn + ".txt"), String(fromText))

      let keys = createTextKeys(fn);
      
      // make a normal file API call
      let URLs = await API.submitTextToSpeech(sessionStorage.getItem(String(fn + ".txt")), String(keys[0]), String(keys[1]), String(keys[2]));

      // Delete the temporary txt file from the local storage
      sessionStorage.removeItem(String(fn + ".txt"))

      setToFileUrl(URLs[0]);
      setToFailFileUrl(URLs[1]);
      textUpdates(3, "NULL", URLs);        

    } catch (error){
      handleError(error, errorMsg422);
    }
  }

  const createTextKeys = (fn) => {

    setOutFn(fn);
    
    // TODO: Differentiate filenames from source and target to increase back-end flexibility
    // Upload and download keys are the same for back-end compatibility purposes
    let uKey = `private/UI/textToSpeech/${userID}/${language}/${language}-${voice}/${fn}.txt`
    let downloadFailKey = `${uKey}_FAIL.json`;
    // let dKey = `private/UI/textToSpeech/${userID}/${language}/${language}-${voice}/${fn}.${toFileExt}`
    let dKey = `private/UI/textToSpeech/${userID}/${language}/${language}-${voice}/${fn}.wav` // Hard-coding to WAV for now

    return [uKey, downloadFailKey, dKey];
  }

  const textUpdates = (step, data, meta) => {
    let msg0 = `Hello! Your text to speech generation is now in progress!\n\n`;
    let msg1 = `${msg0}[Uploading text ${data}%]\n\n`;
    let msg2 = `${msg0}Text uploaded\n\n`;
    let msg3 = `${msg2}[Generating speech audio...]\n`;
    let msg4 = `${msg3}... just letting you know, we are still here generating...`;
    let msg5 = `${msg3}... not to worry, this can take up to 15 minutes for larger texts...`;
    let msg6 = `${msg3}... we are good, doing lots of generating...`;
    let msg7 = `${msg3}... no errors, still generating...`;
    let msg8 = `${msg3}... there is about 3 minutes left of available time...`;
    let msg9 = `[ ** COMPLETE ** ]\n\nYour speech audio is complete! Please download it by clicking the button below.\n\nThank you for using Lilly Translate :)`;
    let msg10 = `[ ** COMPLETE ** ]\n\nYour speech audio is complete! Please download it by clicking the button below.\n\nThank you for using our Lilly Translate :)`;

    switch (step) {
      case 0:
        setShowDownload(true);
        setDisableDownload(true);
        setToLabel(toLabelProgress);
        setToText(msg0);
        setContentTab(resultTab);

        handleScrollBottom();

        break;
      case 1:
        setToText(msg1);

        break;
      case 2:
        setToText(msg2);

        textUpdates(3);

        break;
      case 3:
        setToText(msg3);

        // console.log(toFileUrl);
        // console.log(toFailFileUrl);
        // console.log('testing');

        let dropStatus = [false, false, false, false, false];
        // continuousPollForText(intervalInMs, maxInMs, toFileUrl, toFailFileUrl, dropStatus); // NEED TO FIGURE OUT WHY THIS DOESN'T WORK
        continuousPollForText(intervalInMs, maxInMs, meta[0], meta[1], dropStatus);

        break;
      case 4:
        setToText(msg4);

        break;
      case 5:
        setToText(msg5);

        break;
      case 6:
        setToText(msg6);

        break;
      case 7:
        setToText(msg7);

        break;
      case 8:
        setToText(msg8);

        break;
      case 9:  
        if (fromFileExt === "txt") {
          setToText(msg9);
        } 
        else {
          setToText(msg10);
        }
        setToLabel(toLabelGeneration);
        setDisableDownload(false);
        setDisableRecording(false);
        setactiveJob(false);
        setactiveJobID("");
        setContentTab(resultTab);
        
        handleTimer(false);
        handleScrollBottom();

        break;
      default:
        break;
    }
  }

  // *************************************************************************
  // Handle Document to Speech

  const convertDocToSpeech = async () => {
    try {
      docUpdates(0);
      let tID = handleTranslateID();
      let keys = createKeys(tID);
      
      // make a normal file API call
      let URLs = await API.submitTextToSpeech(fromFile, String(keys[0]), String(keys[1]), String(keys[2]));

      setToFileUrl(URLs[0]);
      setToFailFileUrl(URLs[1]);
      docUpdates(3, "NULL", URLs);        

    } catch (error){
      handleError(error, errorMsg422);
    }
  }

  const createKeys = (tID) => {
    // REMOVE THIS FROM 5.1 once UI file handling lambda uses metdata instead of paths
    let blankedFromFileName = S3Encoding.removeSpecials(fromFileName);
    let prefixBlankedFromFileName = blankedFromFileName.substr(0, blankedFromFileName.lastIndexOf("."));
    prefixBlankedFromFileName = prefixBlankedFromFileName.split(".").join("_");
    tID = prefixBlankedFromFileName; //`${prefixBlankedFromFileName}.${this.state.fromFileExt}`;
    // REMOVE ABOVE

    setOutFn(tID);

    // TODO: Differentiate filenames from source and target to increase back-end flexibility
    // Upload and download keys are the same for back-end compatibility purposes
    let uKey = `private/UI/textToSpeech/${userID}/${language}/${language}-${voice}/${tID}.${fromFileExt}`
    let downloadFailKey = `${uKey}_FAIL.json`;
    // let dKey = `private/UI/textToSpeech/${userID}/${language}/${language}-${voice}/${tID}.${toFileExt}`
    let dKey = `private/UI/textToSpeech/${userID}/${language}/${language}-${voice}/${tID}.wav`  // Hard-coding to WAV for now

    return [uKey, downloadFailKey, dKey];
  }

  const docUpdates = (step, data, meta) => {
    let msg0 = `Hello! Your document to speech generation is now in progress!\n\n`;
    let msg1 = `${msg0}[Uploading document ${data}%]\n\n`;
    let msg2 = `${msg0}Document uploaded\n\n`;
    let msg3 = `${msg2}[Generating speech audio...]\n`;
    let msg4 = `${msg3}... just letting you know, we are still here generating...`;
    let msg5 = `${msg3}... not to worry, this can take up to 15 minutes for larger files...`;
    let msg6 = `${msg3}... we are good, doing lots of generating...`;
    let msg7 = `${msg3}... no errors, still generating...`;
    let msg8 = `${msg3}... there is about 3 minutes left of available time...`;
    let msg9 = `[ ** COMPLETE ** ]\n\nYour speech audio is complete! Please download it by clicking the button below.\n\nThank you for using Lilly Translate :)`;
    let msg10 = `[ ** COMPLETE ** ]\n\nYour speech audio is complete! Please download it by clicking the button below.\n\nThank you for using our Lilly Translate :)`;

    switch (step) {
      case 0:
        setShowDownload(true);
        setDisableDownload(true);
        setToLabel(toLabelProgress);
        setToText(msg0);
        setContentTab(resultTab);

        handleScrollBottom();

        break;
      case 1:
        setToText(msg1);

        break;
      case 2:
        setToText(msg2);

        docUpdates(3);

        break;
      case 3:
        setToText(msg3);

        // console.log(toFileUrl);
        // console.log(toFailFileUrl);
        // console.log('testing');

        let dropStatus = [false, false, false, false, false];
        // continuousPollForDoc(intervalInMs, maxInMs, toFileUrl, toFailFileUrl, dropStatus); // NEED TO FIGURE OUT WHY THIS DOESN'T WORK
        continuousPollForDoc(intervalInMs, maxInMs, meta[0], meta[1], dropStatus);

        break;
      case 4:
        setToText(msg4);

        break;
      case 5:
        setToText(msg5);

        break;
      case 6:
        setToText(msg6);

        break;
      case 7:
        setToText(msg7);

        break;
      case 8:
        setToText(msg8);

        break;
      case 9:  
        if (fromFileExt === "txt") {
          setToText(msg9);
        } 
        else {
          setToText(msg10);
        }
        setToLabel(toLabelGeneration);
        setDisableDownload(false);
        setDisableRecording(false);
        setactiveJob(false);
        setactiveJobID("");
        setContentTab(resultTab);
        
        handleTimer(false);
        handleScrollBottom();

        break;
      default:
        break;
    }
  }

    // The first call to this recursive function should be:
  // interval in ms, max time in ms, "", "", [false, false, false, false, false]
  const continuousPollForDoc = async (interval, max, targetUrl, failUrl, dropStatus) => {
    //const that = this;

    // Get the URL for the target download file
    if (targetUrl === "" || targetUrl === null) {
      
      // (STAGE 2) Get processed File URL.

      setToFileUrl(targetUrl);
    }

    // Get the URL for the fail file
    if (failUrl === "" || failUrl === null) {
      
      // (STAGE 2) Get processed Fail File URL.
      setToFailFileUrl(failUrl);
    }

    // isFile* is for the translated file
    // isFileFail* is for the fail file (*_FAIL.json)
    var isFileOK = false;
    var isFileFailOK = false;

    // Check if the processed file is done yet
    if (targetUrl) {
      isFileOK = await verifyUrlStatus(targetUrl);
    }

    // If file is done, allow the user to download it
    if (isFileOK === true) {
      docUpdates(9);
      max = 0;
      // Else file is not done, run recursion
    } else {
      // Not reached the time limit
      if (max > 0) {
        // The current max minus the interval
        let newMax = max - interval;

        // If 10 seconds has passed:
        // - change interval to 2 seconds (prod)
        // - update message
        if (dropStatus[0] === false && newMax < (maxInMs - maxInMsDeltaDrop0)) {
          interval = intervalInMsDrop0;
          dropStatus[0] = true;
          docUpdates(4);

          // if 1 minute has passed:
          // - change interval to 5 seconds (prod)
          // - check for server failure
          // - update message
        } else if (dropStatus[1] === false && newMax < (maxInMs - maxInMsDeltaDrop1)) {
          interval = intervalInMsDrop1;
          dropStatus[1] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(5);
          }

          // if 2 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[2] === false && newMax < (maxInMs - maxInMsDeltaDrop2)) {
          dropStatus[2] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(6);
          }

          // if 3 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[3] === false && newMax < (maxInMs - maxInMsDeltaDrop3)) {
          dropStatus[3] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(7);
          }

          // if 4 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[4] === false && newMax < (maxInMs - maxInMsDeltaDrop4)) {
          dropStatus[4] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            docUpdates(8);
          }
        }

        // Do recursion if fileFail does not exist
        if (isFileFailOK === false) {
          setTimeout(() => continuousPollForDoc(interval, newMax, targetUrl, failUrl, dropStatus), interval);
        }
        // time limit reached
      } else {
        handleError("Timed out", errorMsgTime);
        max = 0;
      }
    }
  }

  // The first call to this recursive function should be:
  // interval in ms, max time in ms, "", "", [false, false, false, false, false]
  const continuousPollForText = async (interval, max, targetUrl, failUrl, dropStatus) => {
    //const that = this;

    // Get the URL for the target download file
    if (targetUrl === "" || targetUrl === null) {
      
      // (STAGE 2) Get processed File URL.

      setToFileUrl(targetUrl);
    }

    // Get the URL for the fail file
    if (failUrl === "" || failUrl === null) {
      
      // (STAGE 2) Get processed Fail File URL.
      setToFailFileUrl(failUrl);
    }

    // isFile* is for the translated file
    // isFileFail* is for the fail file (*_FAIL.json)
    var isFileOK = false;
    var isFileFailOK = false;

    // Check if the processed file is done yet
    if (targetUrl) {
      isFileOK = await verifyUrlStatus(targetUrl);
    }

    // If file is done, allow the user to download it
    if (isFileOK === true) {
      textUpdates(9);
      max = 0;
      // Else file is not done, run recursion
    } else {
      // Not reached the time limit
      if (max > 0) {
        // The current max minus the interval
        let newMax = max - interval;

        // If 10 seconds has passed:
        // - change interval to 2 seconds (prod)
        // - update message
        if (dropStatus[0] === false && newMax < (maxInMs - maxInMsDeltaDrop0)) {
          interval = intervalInMsDrop0;
          dropStatus[0] = true;
          textUpdates(4);

          // if 1 minute has passed:
          // - change interval to 5 seconds (prod)
          // - check for server failure
          // - update message
        } else if (dropStatus[1] === false && newMax < (maxInMs - maxInMsDeltaDrop1)) {
          interval = intervalInMsDrop1;
          dropStatus[1] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            textUpdates(5);
          }

          // if 2 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[2] === false && newMax < (maxInMs - maxInMsDeltaDrop2)) {
          dropStatus[2] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            textUpdates(6);
          }

          // if 3 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[3] === false && newMax < (maxInMs - maxInMsDeltaDrop3)) {
          dropStatus[3] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            textUpdates(7);
          }

          // if 4 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[4] === false && newMax < (maxInMs - maxInMsDeltaDrop4)) {
          dropStatus[4] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            textUpdates(8);
          }
        }

        // Do recursion if fileFail does not exist
        if (isFileFailOK === false) {
          setTimeout(() => continuousPollForText(interval, newMax, targetUrl, failUrl, dropStatus), interval);
        }
        // time limit reached
      } else {
        handleError("Timed out", errorMsgTime);
        max = 0;
      }
    }
  }

  // The first call to this recursive function should be:
  // interval in ms, max time in ms, "", "", [false, false, false, false, false]
  const continuousPollForTranscrption = async (interval, max, targetUrl, failUrl, dropStatus) => {
    //const that = this;

    // Get the URL for the target download file
    if (targetUrl === "" || targetUrl === null) {
      
      // (STAGE 2) Get processed File URL.

      setToFileUrl(targetUrl);
    }

    // Get the URL for the fail file
    if (failUrl === "" || failUrl === null) {
      
      // (STAGE 2) Get processed Fail File URL.
      setToFailFileUrl(failUrl);
    }

    // isFile* is for the translated file
    // isFileFail* is for the fail file (*_FAIL.json)
    var isFileOK = false;
    var isFileFailOK = false;

    // Check if the processed file is done yet
    if (targetUrl) {
      isFileOK = await verifyUrlStatus(targetUrl);
    }

    // If file is done, allow the user to download it
    if (isFileOK === true) {
      transcribeUpdates(9);
      max = 0;
      // Else file is not done, run recursion
    } else {
      // Not reached the time limit
      if (max > 0) {
        // The current max minus the interval
        let newMax = max - interval;

        // If 10 seconds has passed:
        // - change interval to 2 seconds (prod)
        // - update message
        if (dropStatus[0] === false && newMax < (maxInMs - maxInMsDeltaDrop0)) {
          interval = intervalInMsDrop0;
          dropStatus[0] = true;
          transcribeUpdates(4);

          // if 1 minute has passed:
          // - change interval to 5 seconds (prod)
          // - check for server failure
          // - update message
        } else if (dropStatus[1] === false && newMax < (maxInMs - maxInMsDeltaDrop1)) {
          interval = intervalInMsDrop1;
          dropStatus[1] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            transcribeUpdates(5);
          }

          // if 2 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[2] === false && newMax < (maxInMs - maxInMsDeltaDrop2)) {
          dropStatus[2] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            transcribeUpdates(6);
          }

          // if 3 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[3] === false && newMax < (maxInMs - maxInMsDeltaDrop3)) {
          dropStatus[3] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            transcribeUpdates(7);
          }

          // if 4 minutes has passed:
          // - check for server failure
          // - update message
        } else if (dropStatus[4] === false && newMax < (maxInMs - maxInMsDeltaDrop4)) {
          dropStatus[4] = true;
          isFileFailOK = await verifyUrlStatus(failUrl);
          if (isFileFailOK) {
            handleError(null, errorMsg422);
          } else {
            transcribeUpdates(8);
          }
        }

        // Do recursion if fileFail does not exist
        if (isFileFailOK === false) {
          setTimeout(() => continuousPollForTranscrption(interval, newMax, targetUrl, failUrl, dropStatus), interval);
        }
        // time limit reached
      } else {
        handleError("Timed out", errorMsgTime);
        max = 0;
      }
    }
  }

  const verifyUrlStatus = async (url) => {
    var fileStatus = 0;
    var isOK = false;
    fileStatus = await fetch(url)
      .then(res => res.status)
      .catch(error => {
        // ignore these errors if it occurs
        console.log("Fetch error:" + error);
      });

    if ((fileStatus >= 200 && fileStatus < 300) || fileStatus === 302) {
      isOK = true;
    } else {
      isOK = false;
    }

    return isOK;
  }

  return (
    <div>
      <Grid container spacing={1} className="translateGrid">
        <Grid item xs={12} sm={isMobile ? 12 : 4}>
          <Card className="translateCard">
            <CardContent>
              <p className="translateCardTitle" color="inherit">
                Speech {speechTitle} Options
              </p>
              <Tabs
                value={speechTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeSpeechTabChoice}
                centered
              >
                <Tooltip title="Text to Speech" placement="top-start" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} disableInteractive arrow>
                  <Tab 
                    label="Generate"
                    disabled={disableTTS}
                  />
                </Tooltip>
                <Tooltip title="Speech to Text" placement="top-end" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} disableInteractive arrow>
                  <Tab
                    label="Transcribe"
                    disabled={disableSTT}
                  />
                </Tooltip>              
              </Tabs>
              {(activeJob === false) && (speechTab===ttsTab) &&(
                <Fragment>
                  <Grid item xs={12}>
                    <Tooltip title="Language of Input Text" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        select
                        label={"Source Language"}
                        required
                        value={language}
                        onChange={handleChangeLangChoice}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateLanguageOptions(azureLanguages)}
                      </TextField>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="Gender of Voice" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '1rem' }}>
                        <Typography>Male</Typography>
                        <Switch 
                          defaultChecked
                          inputProps={{ 'aria-label': 'gender toggle' }}
                          color="default"
                          required
                          onChange={toggleGenders}
                        />
                        <Typography>Female</Typography>
                      </Box>
                      {/*<TextField
                        select
                        label={"Gender"}
                        required
                        disabled={genderDisabled}
                        value={gender}
                        onChange={handleChangeGenderChoice}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateGenderOptions(azureLanguages)}
                      </TextField>*/}
                    </Tooltip>
                  </Grid>  
                  <Grid item xs={12}>
                    <Tooltip title="Name of Voice" placement="left" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }} arrow>
                      <TextField
                        select
                        label={"Voice Name"}
                        required
                        disabled={voiceDisabled}
                        value={voice}
                        onChange={handleChangeVoiceChoice}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateVoiceOptions(azureLanguages)}
                      </TextField>
                    </Tooltip>
                  </Grid>                
                  {process.env.REACT_APP_TIMER === "on" && (
                    <p>{activeJobTime}</p>
                  )}
                </Fragment>
              )}
              {(activeJob === false) && (speechTab===sttTab) && (
                <Fragment>
                  <Grid item xs={12}>                   
                    <Tooltip title="Language of Input Speech" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                      <TextField
                        select
                        label={"Source Language"}
                        required
                        value={language}
                        onChange={handleChangeLangChoice}
                        SelectProps={{
                            native: true,
                        }}
                        className="fullSelectors"
                        >
                        {handleGenerateLanguageOptions(azureLanguages)}
                      </TextField>
                    </Tooltip>
                  </Grid>
                </Fragment>
              )}
              {activeJob === true && (
                <Fragment>
                  <Lottie
                    options={aniOptions}
                    height={250}
                    width={250}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickCancel}
                  >
                    Cancel
                  </Button>
                </Fragment>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={isMobile ? 12 : 8}>
          <Card className="translateCard">
            <CardContent>
              <br></br>
              <Tabs
                value={contentTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTabChoice}
              >
                <Tab label={textPage} disabled={disableTextTab}/>
                <Tab label={docPage} />
                <Tab label="Results" />
              </Tabs>
              {(contentTab === textTab) && (speechTab === ttsTab) && (
                <Fragment>
                  <TextField
                    id="contentSource"
                    label={fromLabel}
                    placeholder="Text to Speak"
                    multiline
                    value={fromText}
                    onChange={handleChangeFromText}
                    rows="8"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickConvert}
                    disabled={disableTextProcessing}
                  >
                    Generate
                  </Button>
                </Fragment>
              )}
              {(contentTab === docTab) && (speechTab === ttsTab) && (
                <Fragment>
                  <label htmlFor="fileUpload">
                    <Typography variant="subtitle1" className="fullTypogUpload">
                      <p><b>Generate Speech from Document</b></p>(TXT files)<br/>
                    </Typography>
                    <input
                      type="file"
                      accept=".txt" // .docx
                      onChange={handleChangeUpload}
                      id="fileUpload"
                      className="displayNone"
                      disabled={disableDocUpload}
                    />
                    <Tooltip title="10KB Max size" placement="right" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                      <Button
                        variant="contained"
                        component="span"
                        disabled={disableDocUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload File
                        <VisuallyHiddenInput type="file" />
                      </Button>
                  </Tooltip>
                    <p>{fromFileName}<br/></p>
                  </label>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickConvert}
                    disabled={disableDocProcessing}
                  >
                    Generate
                  </Button>
                </Fragment>
              )}
              {(contentTab === textTab) && (speechTab === sttTab) && (recordingStatus === "inactive") && (
                <Fragment>
                  <Typography variant="subtitle1" className="fullTypogUpload">
                    <p><b>Transcribe Speech from Recorded Audio</b></p>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<RadioButtonCheckedIcon />}
                    onClick={startRecording}
                    disabled={disableRecording}
                  >
                    Start Recording
                  </Button>
                </Fragment>
              )}
              {(contentTab === textTab) && (speechTab === sttTab) && (recordingStatus === "recording") && (
                <Fragment>
                  <Typography variant="subtitle1" className="fullTypogUpload">
                    <p><b>Transcribe Speech from Recorded Audio</b></p>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<StopIcon />}
                    onClick={stopRecording}
                    disabled={disableRecording}
                  >
                    Stop Recording
                  </Button>
                </Fragment>
              )}
              {(contentTab === textTab) && (speechTab === sttTab) && (recordingStatus === "finished") && (
                <Fragment>
                  <Typography variant="subtitle1" className="fullTypogUpload">
                    <p><b>Transcribe Speech from Recorded Audio</b></p>
                  </Typography>
                  <audio src={audio} controls></audio><br/>
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '3rem' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<RadioButtonCheckedIcon />}
                      onClick={startRecording}
                      disabled={disableRecording}
                    >
                      Re-Record
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickConvert}
                      disabled={disableTextProcessing}
                    >
                      Transcribe
                    </Button>
                  </Box>
                </Fragment>
              )}
              {(contentTab === docTab) && (speechTab === sttTab) && (
                <Fragment>
                  <label htmlFor="fileUpload">
                    <Typography variant="subtitle1" className="fullTypogUpload">
                      <p><b>Transcribe Speech from Uploaded Audio File</b></p>(WAV files)<br/>
                    </Typography>
                    <input
                      type="file"
                      accept=".wav" // .mp3
                      onChange={handleChangeUpload}
                      id="fileUpload"
                      className="displayNone"
                      disabled={disableDocUpload}
                    />
                    <Tooltip title="1GB Max size" placement="right" TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                      <Button
                        variant="contained"
                        component="span"
                        disabled={disableDocUpload}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload File
                        <VisuallyHiddenInput type="file" />
                      </Button>
                  </Tooltip>
                    <p>{fromFileName}<br/></p>
                  </label>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickConvert}
                    disabled={disableDocProcessing}
                  >
                    Transcribe
                  </Button>
                </Fragment>
              )}
              {contentTab === resultTab && (
                <Fragment>
                  <TextField
                    id="contentResult"
                    label={toLabel}
                    placeholder={toLabel}
                    multiline
                    value={toText}
                    minRows="8"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                  {showDownload === true && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickDownload}
                      disabled={disableDownload}
                    >
                      Download
                    </Button>
                  )}
                </Fragment>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog fullScreen open={showFullText} onClose={handleCloseFullText} TransitionComponent={Transition}>
        <AppBar className="fullEditAppBar">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseFullText} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="fullEditTitle">
              Full Screen Post-Editing
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClickCopy}>
              Copy to Clipboard
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1} className="fullEditGrid">
          <Grid item xs={12} sm={6}>
            <TextField
              id="contentSourceFullEdit"
              label="Text to Convert (locked)"
              placeholder="Text to Convert"
              multiline
              value={fromText}
              minRows="20"
              margin="normal"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="contentTargetFullEdit"
              label="Translation (edit)"
              placeholder="Converted Text"
              multiline
              value={toText}
              onChange={handleChangeToText}
              minRows="20"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}