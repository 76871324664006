import React from "react";
import Lottie from 'react-lottie';
import '../styles/App.css';
import {
    Grid,
    Box,
    Button
} from "@material-ui/core";
import aniData from "../data/ani-globe.json";
import { useHistory } from "react-router-dom";
import serviceStatuses from "../data/disable-services";

export default function Landing({handleSignIn}) {
  const history = useHistory();
  
  const aniOptions = {
    loop: false,
    autoplay: true,
    animationData: aniData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  // TODO: Fully implement the page for Vision services
  return (
    <div>
      <Grid container spacing={0} className="landingGrid">
        <Grid item xs={12}>
          <Lottie
            options={aniOptions}
            height={500}
            width={500}
          />
        </Grid>
        <Grid item xs={12}>
          <p className="landingDescription">Welcome to Lilly Translate! We are here to support all your language needs.</p>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '3rem' }}>
            <Button variant="contained" size="large" color="primary" onClick={ () => history.push("/translate")} disabled={serviceStatuses.disableTranslate}>
              Translation
            </Button>
            <Button variant="contained" size="large" color="primary" onClick={ () => history.push("/speech")} disabled={serviceStatuses.disableSpeech}>
              Speech
            </Button>
            <Button variant="contained" size="large" color="primary" onClick={ () => history.push("/textanalysis")} disabled={serviceStatuses.disableTextAnalysis}>
              Text Analysis
            </Button>
            <Button variant="contained" size="large" color="primary" onClick={ () => history.push("/vision")} disabled={serviceStatuses.disableVision}>
              Vision
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
